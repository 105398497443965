import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Pagination from "../components/ui/Pagination"
import ArticlesList from "../components/ui/ArticlesList"
import Block from "../components/ui/Block"

export default ({ data }) => (
  <Layout wide>
    <Block wide>
      <ArticlesList articles={data.allMdx.nodes} firstIsFullWidth />
      <Pagination
        current={1}
        total={Math.ceil(data.allMdx.totalCount / 25)}
        linkBase="/all"
      />
    </Block>
  </Layout>
)

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 25) {
      totalCount
      nodes {
        id
        frontmatter {
          title
          date(formatString: "D MMMM YYYY", locale: "fr-FR")
          dateEnd(formatString: "D MMMM YYYY", locale: "fr-FR")
          place
          country
          mainPicture {
            base
            childImageSharp {
              fluid(maxWidth: 1280, srcSetBreakpoints: [400, 960]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
          isPublished
          isPublic
        }
        excerpt
      }
    }
  }
`
